// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/services/ApiService.ts"
);
import.meta.hot.lastModified = "1733883189000";
}
// REMIX HMR END

/* eslint-disable @typescript-eslint/no-var-requires */
import axios, { AxiosResponse } from 'axios';
import { IUpdateUserPayload } from '~/types/IUpdateUserPayload';
import { Company, Invitation } from '../types';
import { IUser } from './types';

export default class APIService {
  private _baseUrl: string;
  private _authToken: string;
  constructor(baseUrl: string, authToken: string = '') {
    this._baseUrl = baseUrl;
    this._authToken = authToken;
  }

  private _getHttpsCustomAgent() {
    // Workaround for ignoring self signed certificate warning
    const https = require('https');
    const agent = new https.Agent({
      rejectUnauthorized: false,
    });
    return agent;
  }

  public _buildHeaders(noCache?: boolean) {
    return {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Cache-Control': noCache ? 'no-cache' : 'default',
    };
  }

  public async SearchUsers(keyword: string): Promise<AxiosResponse> {
    try {
      const agent = this._getHttpsCustomAgent();
      const result = await axios({
        method: 'get',
        url: `${this._baseUrl}/api/account/SearchUsers`,
        params: {
          query: keyword,
        },
        httpsAgent: agent,
      });
      return Promise.resolve(result.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async GetToken(
    username: string,
    password: string
  ): Promise<AxiosResponse> {
    try {
      const result = await axios({
        method: 'post',
        url: `${this._baseUrl}/auth/login`,
        headers: this._buildHeaders(true),
        data: {
          username: username,
          password: password,
        },
      });
      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async AuthClaim(token: string): Promise<AxiosResponse> {
    try {
      const result = await axios({
        method: 'get',
        url: `${this._baseUrl}/auth/claim`,
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async GetUserInfo() {
    try {
      const { data } = await axios<
        Partial<IUser>
      >({
        method: 'get',
        url: `${this._baseUrl}/users/profile`,
        headers: {
          Authorization: 'Bearer ' + this._authToken,
        },
      });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async GetUserInfoById(userId: any): Promise<AxiosResponse> {
    try {
      const { data } = await axios({
        method: 'get',
        url: `${this._baseUrl}/users/${userId}/profile`,
        headers: {
          Authorization: 'Bearer ' + this._authToken,
        },
      });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async UpdateUser(payload: IUpdateUserPayload): Promise<AxiosResponse> {
    try {
      const result = await axios({
        method: 'put',
        url: `${this._baseUrl}/users`,
        headers: {
          Authorization: 'Bearer ' + this._authToken,
        },
        data: payload,
      });
      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async EditUsers({
    id,
    email,
    firstName,
    lastName,
    roleId,
    companyId,
    phoneNumber,
  }: {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    roleId: number;
    companyId: string;
    phoneNumber: string;
  }): Promise<AxiosResponse> {
    try {
      const result = await axios({
        method: 'PUT',
        url: `${this._baseUrl}/users`,
        headers: {
          Authorization: 'Bearer ' + this._authToken,
        },
        data: {
          id: id,
          email: email,
          firstName: firstName,
          lastName: lastName,
          role_id: roleId,
          company_id: companyId,
          phone_number: phoneNumber,
        },
      });
      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async GetUsers({
    page,
    limit = 10,
    sort = 'DESC',
  }: {
    page: number;
    limit?: number;
    sort?: 'ASC' | 'DESC';
  }): Promise<AxiosResponse> {
    try {
      const response = await axios({
        method: 'get',
        url: `${this._baseUrl}/users`,
        headers: {
          Authorization: 'Bearer ' + this._authToken,
        },
        params: {
          page: page,
          limit: limit,
          sort: sort,
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async GetInfoCurrentUser(): Promise<AxiosResponse> {
    try {
      const { data } = await axios({
        method: 'get',
        url: `${this._baseUrl}/users/profile`,
        headers: {
          Authorization: 'Bearer ' + this._authToken,
        },
      });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async GetProfile(userId: string): Promise<AxiosResponse> {
    try {
      const { data } = await axios({
        method: 'get',
        url: `${this._baseUrl}/users/${userId}/profile`,
        headers: {
          Authorization: 'Bearer ' + this._authToken,
        },
      });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async PostUserByInvitation(payload: any): Promise<AxiosResponse> {
    try {
      const result = await axios({
        method: 'post',
        url: `${this._baseUrl}/companies`,
        headers: {},
        data: payload,
      });
      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async UpdateUserProfile(
    userId: string,
    payload: any
  ): Promise<AxiosResponse> {
    try {
      const result = await axios({
        method: 'put',
        url: `${this._baseUrl}/users/${userId}/profile`,
        headers: {
          Authorization: 'Bearer ' + this._authToken,
        },
        data: payload,
      });
      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async UpdateUserByInvitation({
    id,
    email,
    firstName,
    lastName,
    password,
    confirm_password,
    phone,
  }: {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    confirm_password: string;
    phone: string;
  }): Promise<AxiosResponse> {
    try {
      const result = await axios({
        method: 'put',
        url: `${this._baseUrl}/registrations/update`,
        headers: {
          Authorization: 'Bearer ' + this._authToken,
        },
        data: {
          id: id,
          email: email,
          firstName: firstName,
          lastName: lastName,
          password: password,
          confirm_password: confirm_password,
          phone,
        },
      });
      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async GetRolePermissions() {
    try {
      const { data } = await axios<
        Partial<{
          roleName: string;
          roleLabel: string;
          roleLevel: number;
          permissions?:
            | {
                resource: string;
                accessControl: string;
                url: string;
              }[]
            | null;
        }>
      >({
        method: 'get',
        url: `${this._baseUrl}/auth/permissions`,
        headers: {
          Authorization: 'Bearer ' + this._authToken,
        },
      });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async GetTimeZones(): Promise<AxiosResponse> {
    try {
      const { data } = await axios({
        method: 'get',
        url: `${this._baseUrl}/times/zone`,
        headers: {
          Authorization: 'Bearer ' + this._authToken,
        },
      });
      return Promise.resolve(data);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async GetCompanyInfo(companyId: any): Promise<AxiosResponse> {
    try {
      const { data } = await axios({
        method: 'get',
        url: `${this._baseUrl}/companies/${companyId}`,
        headers: {
          Authorization: 'Bearer ' + this._authToken,
        },
      });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async PostCompany(payload: Company): Promise<AxiosResponse> {
    try {
      const result = await axios({
        method: 'post',
        url: `${this._baseUrl}/companies`,
        headers: {
          Authorization: 'Bearer ' + this._authToken,
        },
        data: payload,
      });
      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async AskOtp(userId: string) {
    try {
      const result = await axios<{
        statusCode: number;
        message: string;
      }>({
        method: 'post',
        url: `${this._baseUrl}/auth/otp`,
        data: {
          id: userId,
        },
      });
      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async SubmitOtp(
    userId: string,
    otpNumber: string,
    verification_type?: number
  ): Promise<AxiosResponse> {
    try {
      const response = await axios({
        method: 'post',
        url: `${this._baseUrl}/auth/otp/challenge`,
        data: {
          id: userId,
          verification_code: otpNumber,
          verification_type: verification_type || 1,
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async InviteUser(payload: Invitation): Promise<AxiosResponse> {
    try {
      const result = await axios({
        method: 'post',
        url: `${this._baseUrl}/registrations/invite`,
        headers: {
          Authorization: 'Bearer ' + this._authToken,
        },
        data: payload,
      });
      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async GetInvitationList({
    page = 1,
    limit = 10,
    sort = 'DESC',
  }: any): Promise<AxiosResponse> {
    try {
      const { data } = await axios({
        method: 'get',
        url: `${this._baseUrl}/registrations/invitations`,
        headers: {
          Authorization: 'Bearer ' + this._authToken,
        },
        params: {
          page,
          limit,
          sort,
        },
      });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async CancelInvitation(candidateId: any): Promise<AxiosResponse> {
    try {
      const { data } = await axios({
        method: 'put',
        url: `${this._baseUrl}/registrations/cancel`,
        headers: {
          Authorization: 'Bearer ' + this._authToken,
        },
        data: {
          candidateId,
        },
      });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async ResendInvitation(candidateId: any): Promise<AxiosResponse> {
    try {
      const { data } = await axios({
        method: 'post',
        url: `${this._baseUrl}/registrations/resend`,
        headers: {
          Authorization: 'Bearer ' + this._authToken,
        },
        data: {
          candidateId,
        },
      });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async sendLinkResetPassword(email: string): Promise<AxiosResponse> {
    try {
      const result = await axios({
        method: 'POST',
        url: `${this._baseUrl}/auth/send-link-password`,
        headers: {},
        data: {
          email,
        },
      });
      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async verifyUrlToken(token: string): Promise<AxiosResponse> {
    try {
      const result = await axios({
        method: 'GET',
        url: `${this._baseUrl}/auth/verify-url-token/${token}`,
        headers: {},
      });
      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async fetchResources(): Promise<AxiosResponse> {
    try {
      const result = await axios({
        method: 'GET',
        url: `${this._baseUrl}/resources`,
        headers: {
          Authorization: `Bearer ${this._authToken}`,
        },
      });
      return Promise.resolve(result.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async resetPassword({
    token,
    password,
    confirmedPassword,
  }: {
    token: string;
    password: string;
    confirmedPassword: string;
  }): Promise<AxiosResponse> {
    try {
      const result = await axios({
        method: 'POST',
        url: `${this._baseUrl}/auth/reset-password`,
        headers: {},
        data: {
          token,
          password,
          confirm_password: confirmedPassword,
        },
      });
      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
